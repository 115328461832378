@use "variable" as var;

.modalBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var.$colorRgbaBlack01;
  opacity: 0;
  animation-name: modalFadeIn;
  animation-timing-function: ease;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalContainer {
  position: relative;
  width: 94%;
  min-height: 200px;
  max-height: 80%;
}
