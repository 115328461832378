@use "variable" as var;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

html,
body {
  background-color: var.$black;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  // font-family: "Noto Sans JP", sans-serif;
  font-family: "M PLUS Rounded 1c", "游ゴシック体", "Yu Gothic", YuGothic,
    Meiryo, "メイリオ", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",
    "ＭＳ Ｐゴシック", "MS PGothic", arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*ルビ系*/
.furigana {
  line-height: 1.7;
}
ruby[data-ruby] {
  position: relative;
  display: inline-block;
  &::before {
    content: attr(data-ruby);
    position: absolute;
    top: 0;
    left: -3em;
    right: -3em;
    font-size: 1em;
    line-height: 0.2;
    text-align: center;
    transform: scale(0.4);
  }
  rt {
    display: none;
  }
}
