@use "variable" as var;

.leadTxt {
  display: block;
  margin-bottom: calc(var.$length16);
  line-height: 180%;
}
.need {
  color: red;
}
.inputWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & + * {
    margin-top: 0.5em;
  }
  .radio,
  .check {
    display: block;
    margin-right: 0.5em;
    // width: 12px;
    height: 12px;
  }
  .inputTxt {
    display: block;
    padding-top: 0.2em;
    line-height: 1.5;
  }
}
