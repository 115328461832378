.fade-in {
  animation: animation-fade-in 1s;
}

.fade-in-fast {
  animation: animation-fade-in 0.3s;
}

.fade-in-slow {
  animation: animation-fade-in 1.5s;
}

@keyframes animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-fade-in-fast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-fade-in-slow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scale-up-bottom:hover {
  animation: scale-up-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.heartbeat:hover {
  animation: heartbeat 1.5s ease-in-out both;
}

@keyframes scale-up-bottom {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  65% {
    transform: scale(1.1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes fuwafuwa01 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
  }
}

@mixin animeFuwafuwa01 {
  animation-name: fuwafuwa01;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes pyokopyoko {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@mixin animePyokoPyoko {
  animation-name: pyokopyoko;
  animation-duration: 1.6s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.yRotation {
  animation: 5s linear infinite rotation;
}

@keyframes rotation {
  0% {
    transform: rotateY(0) translateY(0);
  }
  90% {
    transform: rotateY(0) translateY(0);
  }
  95% {
    transform: rotateY(180deg) translateY(-5%);
  }
  100% {
    transform: rotateY(0) translateY(0);
  }
}

@keyframes kurukuru01 {
  0% {
    transform: rotateX(0deg) translateX(3px);
  }
  50% {
    transform: rotateX(180deg) translateX(0);
  }
  100% {
    transform: rotateX(0) translateX(3px);
  }
}

@mixin animeKuruKuru01 {
  animation-name: kurukuru01;
  animation-duration: 1.6s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
